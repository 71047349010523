@font-face {
    font-family: 'Bicyclette';
    src: url('../fonts/Bicyclette-Regular.woff');
}

@font-face {
    font-family: 'Capitolium';
    src: url('../fonts/CapitoliumRegularRoman.otf');
}

* {
    box-sizing: border-box;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: 'Bicyclette';
    font-weight: 400;
    color: #F9F4EC;
}

html {
    scroll-behavior: smooth;
    -ms-scroll-snap-type: y proximity;
        -webkit-scroll-snap-type: y proximity;
            scroll-snap-type: y proximity;  
    overflow-y: scroll;
}

body {
    margin: 0;
    background: #141313;
}

.app {
    text-align: center;
    scroll-behavior: smooth;
    -ms-scroll-snap-type: y mandatory;
        -webkit-scroll-snap-type: y mandatory;
            scroll-snap-type: y mandatory;
}

h1, h2, h3, h4, h5, ul, p, a {
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 150px;
}

p {
    font-family: 'Capitollium';
    line-height: 1.8;
    font-size: 22px;
    font-weight: 100;
}

a {
    text-decoration: none;
}
  
li {
    list-style-type: none;
}
  
button {
    background: none;
    outline: unset;
    border: unset;
  
    &:hover {
      cursor: pointer;
    }
}

.btn {
    padding: 10px 30px;
    border: solid 1px #F9F4EC;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background: #F9F4EC;
        color: #141313;
    }
}

.hide {
    display: none;
}

input {
    background: none;
    outline: none;
    border: none;
    font-size: 20px;
    border-bottom: solid 1px #F9F4EC;
    font-weight: 200;
    font-size: 32px;

    &::-webkit-input-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &::-moz-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &:-ms-input-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &::-ms-input-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &::-webkit-input-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &::-moz-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &:-ms-input-placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }

    &::placeholder {
        color: #F9F4EC;
        font-size: 24px;
    }
}

@media(max-width: 1100px) {
    h2 { font-size: 120px; }
    p { font-size: 20px; }
    .btn { font-size: 18px; }
    input {
        font-size: 30px;
        &::-webkit-input-placeholder {
            font-size: 22px;
        }
        &::-moz-placeholder {
            font-size: 22px;
        }
        &:-ms-input-placeholder {
            font-size: 22px;
        }
        &::-ms-input-placeholder {
            font-size: 22px;
        }
        &::-webkit-input-placeholder {
            font-size: 22px;
        }
        &::-moz-placeholder {
            font-size: 22px;
        }
        &:-ms-input-placeholder {
            font-size: 22px;
        }
        &::placeholder {
            font-size: 22px;
        }
    }
}

@media(max-width: 900px) {
    h2 { font-size: 100px; }
    p { font-size: 18px; }
    .btn { font-size: 16px; }
    input {
        font-size: 28px;
        &::-webkit-input-placeholder {
            font-size: 20px;
        }
        &::-moz-placeholder {
            font-size: 20px;
        }
        &:-ms-input-placeholder {
            font-size: 20px;
        }
        &::-ms-input-placeholder {
            font-size: 20px;
        }
        &::-webkit-input-placeholder {
            font-size: 20px;
        }
        &::-moz-placeholder {
            font-size: 20px;
        }
        &:-ms-input-placeholder {
            font-size: 20px;
        }
        &::placeholder {
            font-size: 20px;
        }
    }
}

@media(max-width: 700px) {
    h2 { font-size: 80px; }
    p { font-size: 16px; }
    .btn { 
        font-size: 14px; 
        padding: 5px 20px;
    }
    input {
        font-size: 26px;
        &::-webkit-input-placeholder {
            font-size: 18px;
        }
        &::-moz-placeholder {
            font-size: 18px;
        }
        &:-ms-input-placeholder {
            font-size: 18px;
        }
        &::-ms-input-placeholder {
            font-size: 18px;
        }
        &::-webkit-input-placeholder {
            font-size: 18px;
        }
        &::-moz-placeholder {
            font-size: 18px;
        }
        &:-ms-input-placeholder {
            font-size: 18px;
        }
        &::placeholder {
            font-size: 18px;
        }
    }
}

@media(max-width: 515px) {
    h2 { font-size: 60px; }
    p { font-size: 14px; }
    // .btn { 
    //     font-size: 12px; 
    //     padding: 5px 20px;
    // }
    input {
        font-size: 24px;
        &::-webkit-input-placeholder {
            font-size: 16px;
        }
        &::-moz-placeholder {
            font-size: 16px;
        }
        &:-ms-input-placeholder {
            font-size: 16px;
        }
        &::-ms-input-placeholder {
            font-size: 16px;
        }
        &::-webkit-input-placeholder {
            font-size: 16px;
        }
        &::-moz-placeholder {
            font-size: 16px;
        }
        &:-ms-input-placeholder {
            font-size: 16px;
        }
        &::placeholder {
            font-size: 16px;
        }
    }
}


@media(max-width: 400px) {
    h2 { font-size: 50px; }
}

/*================ SASS HELPERS ================*/
/*============================================================================
  Convert pixels to ems
  eg. for a relational value of 12px write em(12) when the parent is 16px
  if the parent is another value say 24px write em(12, 24)
  Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_px-to-em.scss
==============================================================================*/
@function em($pxval, $base: $font-size-base) {
    @if not unitless($pxval) {
      $pxval: strip-units($pxval);
    }
    @if not unitless($base) {
      $base: strip-units($base);
    }
    @return ($pxval / $base) * 1em;
}