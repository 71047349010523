#hero {
    height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    -o-object-fit: contain;
       object-fit: contain;
    background: none;

    div {
        video {
            z-index: -1;
            position: fixed;
            // center video for all screen sizes 
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    h1 {
        padding: 20px; 
        img {
            max-width: 85%;
            height: auto;
            width: auto\9;
        }
    }
}

main {
    section {
        height: 100vh;
        background: #141313;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        scroll-snap-align: start;

        @media(max-width: 700px) {
            scroll-snap-align: none;
        }
    
        .content {
            max-width: 1000px;
            margin-right: auto;
            padding: 80px;
            text-align: left;
            opacity: 0;
            -webkit-transition: all 0.6s ease;
            transition: all 0.6s ease;

            h2 {
                margin: 60px 0 40px;
            }
    
            p {
                margin-top: 20px;
            }

            .btn-wrap {
                margin-top: 60px;

                @media(max-width: 700px) {
                    margin-top: 40px;
                }
            }

            @media(max-width: 1000px) {
                max-width: 85%;
            }

            @media(max-width: 840px) {
                padding: 40px;
                h2 {
                    margin-top: 100px;
                    margin-bottom: 20px;
                }
            }

            @media(max-width: 515px) {
                padding-right: 0;
            }
        }

        .content.active {
            opacity: 1;
        }


        @media(max-width: 700px) {
            padding-bottom: 60px;
        }
    }
}

#contact {
    .content {
        h3 {
            a {
                text-decoration: underline;
            }
        }

        @media(max-width: 900px) {
            max-width: 100%;
            width: 100%;
        }

        @media(max-width: 700px) {
            h3 {
                font-size: 16px;
                padding-right: 40px;
            }
        }

        @media(max-width: 515px) {
            h3 {
                font-size: 14px;
            }
        }

        #contact-form {
            width: 1000px;

            @media (max-width: 1200px) {
                width: 100%;
            }

            @media(max-width: 840px) {
                padding-right: 60px;
            }
        
            input {
                margin: 20px 0;
                width: 100%;
            }
        
            button {
                margin-top: 40px;
            }

            .error {
                color: #CB5D67;
            }
        }

        .ty-wrap {
            opacity: 0;
            height: 0;
            -webkit-transition: all 1s ease;
            transition: all 1s ease;

            p { 
                font-family: 'Bicyclette';
                padding-right: 20px;
                
                a {
                    color: #B79A85;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .ty-wrap.active {
            opacity: 1;
            height: auto;
        }
    }
    .content.active {
        opacity: 1;
    }
}