#nav-wrap {
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px;

    .toggler {
        padding: 20px;
        width: 80px;
        margin-left: auto;
        cursor: pointer;
        z-index: 2;

        @media(max-width: 840px) {
            width: 75px;   
        }

        @media(max-width: 700px) {
            width: 70px;   
        }
    }

    @media(max-width: 700px) {
        padding-right: 0;
    }

    .hamburger {
        cursor: pointer;
        width: 100%;

        .line {
            position: relative;
            width: 100%;
            height: 2px;
            background-color: #F9F4EC;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-transition: all 0.6s ease;
            transition: all 0.6s ease;

            &:before, &:after {
                content: '';
                position: absolute;
                top: -10px;
                width: 100%;
                height: 2px;
                background: inherit;
            }

            &:after {
                top: 10px;
            }
        }
    }

    // turn lines in X
    .hamburger.active {
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg);

        .line {
            background-color: #141313;
            &:before, &:after {
                top: 0;
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
            }
        }
    }

    .menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        visibility: hidden;
        background-color: #E0DDD5;
        opacity: 0;
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
        padding: 20px;

        .logo-link-wrap {
            width: fit-content;
            margin: auto;
        }

        a {
            cursor: pointer;
            
            h1 {
                img {
                    width: 250px;

                    @media(max-width: 700px) {
                        margin-bottom: 40px;
                        width: 200px;
                    }

                    @media(max-width: 515px) {
                        margin-bottom: 20px;
                        width: 180px;
                    }

                    @media(max-width: 350px) {
                        width: 160px;
                    }
                }
            }
        }

        nav {
            padding-top: 40px;

            @media(max-width: 700px) {
                padding-top: 0;
            }

            ul {
                
                li {
                    margin: 70px 0;
                    a {
                        font-size: 28px;
                        color: #141313;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media(max-width: 700px) {
                            font-size: 24px;
                        }

                        @media(max-width: 515px) {
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        .locations {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;

            h3.note {
                color: #141313;
                margin-top: 20px;
                padding: 0 20px;
            }

            @media(max-width: 700px) {
                h3 {
                    font-size: 16px;
                }
            }
    
            @media(max-width: 515px) {
                h3 {
                    font-size: 14px;
                }
            }

            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;

                li {
                    margin: 0 40px;

                    h3 {
                        font-size: 22px;
                        line-height: 1.5;
                        color: #141313;

                        span, a {
                            display: block;
                            color: #141313
                        }

                        a {
                            text-decoration: underline;
                        }
                    }

                    h4 {
                        font-size: 22px;
                    }

                    @media(max-width: 900px) {
                        h3, h4 {
                            font-size: 18px;
                        }
                    }

                    @media(max-width: 700px) {
                        margin: 0 20px;

                        h3, h4 {
                            font-size: 16px;
                        }
                    }

                    @media(max-width: 500px) {
                        margin: 0 10px;

                        h3, h4 {
                            font-size: 14px;
                        }
                    }

                    @media(max-width: 350px) {
                        margin: 0 10px;

                        h3, h4 {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .menu.show {
        opacity: 1;
        visibility: visible;
        z-index: -1;
    }
}