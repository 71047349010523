#footer {
    width: 100%;
    border-top: solid 1px #F9F4EC;
    background: #141313;
    padding: 80px;
    scroll-snap-align: start;

    @media(max-width: 840px) {
        padding: 40px;
    }

    @media(max-width: 700px) {
        scroll-snap-align: none;
    }

    footer {
        .flex-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            @media(max-width: 900px) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -webkit-box-align: start;
                    -ms-flex-align: start;
                        align-items: flex-start;
            }

            .newsletter {
                text-align: left;

                h3 {
                    font-size: 48px;
                    margin-bottom: 20px;

                    @media(max-width: 1100px) {
                        font-size: 44px;
                    }

                    @media(max-width: 900px) {
                        font-size: 40px;
                    }

                    @media(max-width: 700px) {
                        font-size: 36px;
                    }

                    @media(max-width: 515px) {
                        font-size: 32px;
                    }
                }

                .input-wrap {
                    text-align: left;
                    width: 500px;

                    input {
                        font-size: 24px;
                        width: 100%;
                        
                        @media(max-width: 1100px) {
                            font-size: 22px;
                        }

                        @media(max-width: 900px) {
                            font-size: 20px;
                        }
                    }                    
                }

                input {
                    font-size: 24px;
                    width: 100%;
                    
                    @media(max-width: 1100px) {
                        font-size: 22px;
                    }

                    @media(max-width: 900px) {
                        font-size: 20px;
                    }
                }

                button {
                    margin-top: 25px;
                    display: block;
                }

                .error {
                    margin-top: 20px;
                    color: #CB5D67;
                }

                .message {
                    margin-top: 20px;
                }

                @media(max-width: 700px) {
                    .input-wrap {
                        width: 130%;

                        input {
                            font-size: 18px;
                        }
                    }
                }

                @media(max-width: 515px) {
                    width: 70%;

                    .input-wrap {
                        width: 100%;

                        input {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .social-links {
            width: 100%;

            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                justify-content: flex-end;

                li {
                    margin: 0 20px;
                    
                    i {
                        font-size: 24px;

                        @media(max-width: 1100px) {
                            font-size: 22px;
                        }

                        @media(max-width: 900px) {
                            font-size: 20px;
                        }

                        @media(max-width: 700px) {
                            font-size: 18px;
                        }

                        @media(max-width: 515px) {
                            font-size: 16px;
                        }
                    }
                }

                @media(max-width: 900px) {
                    margin-top: 40px;
                    li {
                        &:nth-child(1) {
                            margin-left: 0;
                        }
                    }
                }
            }


            .copyright-content {
                small {
                    display: block;
                    margin: 20px;
                    font-size: 16px;
                    text-align: right;
                }

                a {
                    text-decoration: underline;
                }
            }

            @media (max-width: 515px) {
                position: relative;
                right: -20px;
            }
        }

        .locations {
            padding-top: 60px;
            ul {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;

                li {
                    margin: 0 40px;

                    h3 {
                        font-size: 22px;
                        line-height: 1.5;

                        span, a {
                            display: block;
                        }

                        a {
                            text-decoration: underline;
                        }
                    }

                    h4 {
                        font-size: 22px;
                    }

                    @media(max-width: 1100px) {
                        h3, h4 {
                            font-size: 20px;
                        }
                    }

                    @media(max-width: 900px) {
                        h3, h4 {
                            font-size: 18px;
                        }
                    }

                    @media(max-width: 700px) {
                        margin: 0 20px;

                        h3, h4 {
                            font-size: 16px;
                        }
                    }
                }

                @media(max-width: 515px) {
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                    -webkit-box-align: end;
                        -ms-flex-align: end;
                            align-items: flex-end;
                    
                    li {
                        margin: 10px 0;
                        text-align: right;
                    }
                }
            }
        }
    }
}